import './Footer.css';

export interface IFooterProps {
    text: string;
}

export function Footer(props: IFooterProps): JSX.Element {
    return (
        <footer>
            <div className="footer-content">
                <hr />
                <p>{props.text}</p>
            </div>
        </footer>
    );
}
