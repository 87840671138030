import { IPageProps, Page } from '../components/Page';
import { Layout } from '../data/Layout';
import { GetProduct } from '../data/Products';
import { FormatLongProductListing, FormatLongProductScreenshots } from './Format';

export interface IProductPageProps {
    id: string
}

export function ProductPage(props: IProductPageProps): JSX.Element {
    const product = GetProduct(props.id)
    const pageProps: IPageProps = {
        header: {
            text: Layout.header
        },
        main: {
            sections: FormatLongProductListing(product).concat(FormatLongProductScreenshots(product))
        },
        footer: {
            text: Layout.footer
        }
    };
    return (
        <Page {...pageProps} />
    );
}
