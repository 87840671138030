import { useState } from 'react';
import './Carousel.css';


export interface ICarouselProps {
    imagePaths: string[];
    imageLabels: string[];
}

export function Carousel (props: ICarouselProps): JSX.Element {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextSlide = (): void => {
        const index = (currentImageIndex + 1) % props.imagePaths.length;
        setCurrentImageIndex(index);
    };

    const prevSlide = (): void => {
        const index = (currentImageIndex - 1 + props.imagePaths.length) % props.imagePaths.length;
        setCurrentImageIndex(index);
    };

    return (
        <div className="carousel-container">
            <div className="carousel-slide">
                <img src={props.imagePaths[currentImageIndex]} alt={props.imageLabels[currentImageIndex]} />
            </div>
            <div className="carousel-navigation">
                <a className="carousel-chevron" aria-label="previous slide" onClick={prevSlide}>&#10094;</a>
                {Array.from({ length: props.imagePaths.length }).map((_, i) => (
                    <span className={i == currentImageIndex ? "carousel-dot-selected" : "carousel-dot-not-selected"} onClick={() => setCurrentImageIndex(i)} />
                ))}
                <a className="carousel-chevron" aria-label="next slide" onClick={nextSlide}>&#10095;</a>

            </div>
        </div>
    );
}
