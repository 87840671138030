import { ISectionProps, Section } from './Section';
import './Main.css';

export interface IMainProps {
    sections: ISectionProps[];
}

export function Main(props: IMainProps): JSX.Element {
    return (
        <main>
            <div className="main-content">
                {props.sections.map((section: ISectionProps): JSX.Element => <Section {...section} />)}
            </div>
        </main>
    );
}
