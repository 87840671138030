export interface ISection {
    id: string;
    header: string;
    paragraphs: string[];
}

const Sections: ISection[] = [
    {
        id: "about",
        header: "About",
        paragraphs: [
            "We create productivity software solutions that empower users to work smarter and more efficiently."
        ]
    },
    {
        id: "privacy",
        header: "Privacy",
        paragraphs: [
            "Our privacy policy is straightforward: we do not collect any personal data.",
            "However, we do collect non-personal data related to the usage of our website and applications. This helps us identify issues you might encounter and allows us to enhance your user experience and overall product quality.",
        ]
    },
    {
        id: "contact",
        header: "Contact",
        paragraphs: [
            "If you wish to provide feedback, report a problem, make a suggestion, or simply ask a question, please send us an email using the address below.",
            "contact@gucciosoftware.com",
        ]
    },
]

export function GetSection(id: string): ISection {
    const section = Sections.filter(s => s.id === id).shift() || null
    if (section == null) {
        throw Error(`key ${id} not found`)
    }
    return section
}
