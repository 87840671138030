import { IPageProps, Page } from '../components/Page';
import { ISectionProps } from '../components/Section';
import { Layout } from '../data/Layout';
import { GetProducts, IProduct } from '../data/Products'
import { GetSection } from '../data/Sections';
import { FormatShortProductListing, FormatSimpleSection } from './Format';

export function HomePage(): JSX.Element {
    const pageProps: IPageProps = {
        header: {
            text: Layout.header
        },
        main: {
            sections: GetProducts().map((product: IProduct): ISectionProps => FormatShortProductListing(product))
                .concat(FormatSimpleSection(GetSection("about")))
                .concat(FormatSimpleSection(GetSection("privacy")))
                .concat(FormatSimpleSection(GetSection("contact"))),
        },
        footer: {
            text: Layout.footer
        }
    };
    return (
        <Page {...pageProps} />
    );
}
