import { Footer, IFooterProps } from './Footer';
import { Header, IHeaderProps } from './Header';
import { Main, IMainProps } from './Main';

export interface IPageProps {
    header: IHeaderProps;
    main: IMainProps;
    footer: IFooterProps;
}

export function Page(props: IPageProps): JSX.Element {
    return (
        <>
            <Header text={props.header.text} />
            <Main sections={props.main.sections} />
            <Footer text={props.footer.text} />
        </>
    );
}
