export interface IProduct {
    id: string
    name: string
    description: string[]
    shortName: string
    shortDescription: string
    screenshotCount: number
    storeId?: string
}

const Products: IProduct[] = [
    {
        id: "hexeditor",
        name: "Guccio Hex Editor",
        description: [
            "Guccio Hex Editor is a powerful tool for binary editing. Its outstanding ability to handle large files makes it a robust tool for any binary editing task. Its sleek and minimalistic user interface is designed for ease of use and allows for quick and easy navigation.",
            "The editor conveniently displays the binary data in three distinct columns: the linear address, the hexadecimal representation, and the ASCII encoding. This layout provides a comprehensive view of your binary data, making it easier to understand and manipulate. The editor allows you to create new binary files, as well as open and modify existing ones. You have the flexibility to edit your binaries by overwriting, inserting, or deleting bytes in either the hexadecimal or ASCII column. The editor also supports standard operations such as undo/redo, cut/copy/paste, and find/replace, thereby enhancing your productivity and efficiency.",
        ],
        shortName: "Hex Editor",
        shortDescription: "Hex Editor is a powerful tool for binary editing.",
        screenshotCount: 3,
        storeId: "9nvjbm0fm22t",
    },
    {
        id: "texteditor",
        name: "Guccio Text Editor",
        description: [
            "Guccio Text Editor is a simple tool for editing UTF-8 encoded text files. Its outstanding ability to handle large files makes it a robust tool for any text editing task. Its sleek and minimalistic user interface is designed for ease of use and allows for quick and easy navigation.",
            "The editor allows you to create new text files, as well as open and modify existing ones. You have the flexibility to edit your texts by inserting, overwriting, or deleting characters. The editor also supports standard operations such as undo/redo, and cut/copy/paste, thereby enhancing your productivity and efficiency."
        ],
        shortName: "Text Editor",
        shortDescription: "Text Editor is a simple tool for editing UTF-8 encoded text files.",
        screenshotCount: 1,
        storeId: "9nkw6kcg9qfk",
    }
]

export function GetProducts(): IProduct[] {
    return Products

}

export function GetProduct(id: string): IProduct {
    const product = Products.filter(p => p.id === id).shift() || null
    if (product == null) {
        throw Error(`key ${id} not found`)
    }
    return product
}
