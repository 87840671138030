import './Header.css';

export interface IHeaderProps {
    text: string;
}

export function Header(props: IHeaderProps): JSX.Element {
    return (
        <header>
            <div className="header-content">
                <div className="header-branding">{props.text}</div>
            </div>
        </header>
    );
}
