import { Carousel } from "../components/Carousel"
import { HeaderId, IParagraphProps, ISectionProps, ParagraphId } from "../components/Section"
import { IProduct } from "../data/Products"
import { ISection } from "../data/Sections"

export function FormatShortProductListing(product: IProduct): ISectionProps {
    return {
        header: {
            id: HeaderId.DefaultWithLogo,
            text: product.shortName,
            imagePath: "/assets/images/" + product.id + "/logo.png",
            imageLabel: "logo"
        },
        paragraphs: [
            {
                id: ParagraphId.DefaultWithLinks,
                text: product.shortDescription,
                links: [
                    { text: "more", path: "/" + product.id, label: "more" },
                ]
            }
        ]
    }
}

export function FormatLongProductListing(product: IProduct): ISectionProps[] {
    return [
        {
            header: {
                id: HeaderId.DefaultWithLogo,
                text: product.name,
                imagePath: "/assets/images/" + product.id + "/logo.png",
                imageLabel: "logo"
            },
            paragraphs: product.description.map((paragraph: string): IParagraphProps => { return { id: ParagraphId.Default, text: paragraph, links: [] }; }).concat(
                {
                    id: ParagraphId.LinkWithImage,
                    text: "",
                    links: [
                        { text: "", path: "https://apps.microsoft.com/detail/" + product.storeId + "?cid=storebadge", label: "download" },
                        { text: "", path: "/assets/images/storebadge.png", label: "download" }
                    ]
                }
            ),
        }
    ]
}

function Range(count: number): Array<number> {
    return Array.from({ length: count }, (_, key) => key)
}

function ScreenshotPaths(product: IProduct): string[] {
    return Range(product.screenshotCount).map((i: number): string => { return "/assets/images/" + product.id + "/screenshot-" + i + ".png" })
}

function ScreenshotLabels(product: IProduct): string[] {
    return Range(product.screenshotCount).map((i: number): string => { return "screenshot " + i })
}

export function FormatLongProductScreenshots(product: IProduct): ISectionProps[] {
    return [
        {
            header: {
                id: HeaderId.Default,
                text: "Screenshots",
            },
            paragraphs: [
                {
                    id: ParagraphId.Element,
                    text: "",
                    links: [],
                    element: <Carousel imagePaths={ScreenshotPaths(product)} imageLabels={ScreenshotLabels(product)} />
                }
            ]
        }
    ]
}

export function FormatSimpleSection(section: ISection): ISectionProps {
    return {
        header: {
            id: HeaderId.Default,
            text: section.header,
        },
        paragraphs: section.paragraphs.map((paragraph): IParagraphProps => { return { id: ParagraphId.Default, text: paragraph, links: [] }; })
    }
}
