import './Section.css'

export enum HeaderId {
    Default,
    DefaultWithLogo
}

export interface IHeaderProps {
    id: HeaderId;
    text: string;
    imagePath?: string;
    imageLabel?: string;
}

export enum ParagraphId {
    Default,
    DefaultWithLinks,
    LinkWithImage,
    Element,
}

export interface IParagraphLink {
    text: string;
    path: string;
    label: string;
}

export interface IParagraphProps {
    id: ParagraphId;
    text: string;
    links: IParagraphLink[];
    element?: JSX.Element;
}

export interface ISectionProps {
    header: IHeaderProps;
    paragraphs: IParagraphProps[];
}

function Link(link: IParagraphLink): JSX.Element {
    return <a href={link.path} aria-label={link.label}>{link.text}</a>;
}

function LinkWithImage(link: IParagraphLink, img: IParagraphLink): JSX.Element {
    return <a href={link.path} aria-label={link.label}><img src={img.path} alt={img.label} height={"50px"}></img></a>;
}

function Links(links: IParagraphLink[]): JSX.Element[] {
    return links.map((l: IParagraphLink): JSX.Element => { return <span className="section-link-separated">{Link(l)}</span>; });
}

//            public Section AddLinkWithImage(string linkPath, string imagePath, string label) {
//                XmlElement a = new XmlElement("a");
//    a.AddAttribute("href", linkPath);
//    a.AddAttribute("aria-label", label);
//                XmlElement img = a.AddChild("img");
//    img.AddAttribute("src", imagePath);
//    img.AddAttribute("alt", label);
//    img.AddAttribute("height", "50px");
//    AddChild(a);
//    return this;
//}


export function Section(props: ISectionProps): JSX.Element {
    return (
        <section>
            {props.header.id === HeaderId.Default ?
                <h1 className="section-title">{props.header.text}</h1>
            :
                <hgroup>
                    <img className="section-image" src={props.header.imagePath} alt={props.header.imageLabel} />
                    <h1 className="section-image-title">{props.header.text}</h1>
                </hgroup>
            }
            {props.paragraphs.map((paragraph: IParagraphProps): JSX.Element =>
                paragraph.id === ParagraphId.Default ?
                    <p>{paragraph.text}</p>
                : paragraph.id === ParagraphId.DefaultWithLinks ?
                    <p>{paragraph.text}{paragraph.links && Links(paragraph.links)}</p>
                : paragraph.id === ParagraphId.LinkWithImage ?
                    <p>{LinkWithImage(paragraph.links[0], paragraph.links[1])}</p>
                :
                    <p>{paragraph.element}</p>
            )}
        </section>
    );
}
